import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../modules/auth/services/auth.service';
import { TokenStorageService } from '../modules/auth/services/token-storage.service';
import { from, map } from 'rxjs';

export const isAuthenticatedGuard: CanActivateFn = (route, state) => {
  
  const authService = inject( AuthService );
  const router      = inject( Router );
  const token       = inject( TokenStorageService);


  var valToken = token.getToken();
  
  if (valToken != null){
    return from(authService.verifyToken(valToken.access_token)).pipe(
      map((valTokenCaducidad) => {
        console.log(valTokenCaducidad);
        if(valTokenCaducidad != false){
          return true;
        }else{
          router.navigateByUrl('/admin');
          return false;          
        }
      })
    );
  }
  router.navigateByUrl('/admin');
  return false;
};
