import { inject } from '@angular/core';
import {  CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../modules/auth/services/auth.service';
import { TokenStorageService } from '../modules/auth/services/token-storage.service';
import { from, map, of } from 'rxjs';
import { MealsCartService } from '../modules/fit-meals-v2/services/meals-cart.service';
import { Cart } from '../modules/fit-meals-v2/interfaces/cart.interface';


export const isAuthenticatedClientGuard: CanActivateFn = (route, state) => {

  const authService = inject( AuthService );
  const router      = inject( Router );
  const token       = inject( TokenStorageService);

  var valToken = token.getTokenClient();

  if (valToken != null){
    return from(authService.verifyToken(valToken.access_token)).pipe(
      map((valTokenCaducidad) => {
        console.log(valTokenCaducidad);
        if(valTokenCaducidad != false){
          return true;
        }else{
          localStorage.removeItem('cart');
          authService.isLoged = of(false);
          router.navigate(['/sign-in'], {
            queryParams: { returnUrl: state.url },
          });
          return false;
        }
      })
    );
    }
    authService.isLoged = of(false);
    router.navigate(['/sign-in'], {
      queryParams: { returnUrl: state.url },
    });
    return false;

};
